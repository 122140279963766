import React, { useState, useEffect } from 'react'
import '../../Participate.scss'
import { Container, Row, Col } from 'react-bootstrap'
import ImageGallery from 'react-image-gallery';
import {donors1} from '../DonorsData'

function Donor(props) {
  const [donorData, setDonorData] = useState({description: [""]})
  const [images, setImages] = useState([])
 
  useEffect(() => {
    setDonorData(donors1.find(x => x.path === props.match.params.donorName))
  },[])

  useEffect(() => {
    if(donorData.imgPath){
      let imagePath = donorData.imgPath + "/img"
      var imgs = 0
      switch(donorData.path){
        case "goradia":
          imgs = 1
          break;
        // case "january-2020":
        //   imgs = 19
        //   break;
        // case "december-2019":
        //   imgs = 12
        //   break;
        // case "november-2019":
        //   imgs = 27
        //   break;
        // case "october-2019":
        //   imgs = 4
          // break;
      }
      for(var i=1;i<=imgs;i++){
        setImages([...images,{
          original: imagePath + i + ".jpg",
          thumbnail: imagePath + i + ".jpg"
        }])
      }
    }
  }, [donorData])

  return (
    <div className="activity-page">
      <section id="title"><h1>{donorData.name}</h1></section>
      <section id="activity-section">
        {images!=0 ? <ImageGallery items={images} className="pb-50"/> : <></>}
        <br></br>
        <Container className="data-desc">
            {donorData.description.map(para => <p>{para}</p>)}
            {donorData.html}
        </Container>
      </section>
    </div>
  );
}

export default Donor
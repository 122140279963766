export const founders = [
    {
        imgSrc : "/assets/team/shaurya.jpeg",
        name: "Shaurya Kandoi",
        designation: "Founder",
        description:"Shaurya is a natural athlete, with a passion for basketball, badminton and football. Beyond sports he enjoys debates and playing the guitar."
    },
    {
        imgSrc : "/assets/team/siddh.jpeg",
        name: "Siddh Chand",
        designation: "Founder",
        description:"Siddh is a typical brainiac, being an International-level Chess player. Additionally, he has an artistic eye and is an excellent painter. Siddh Chand, will be embarking his undergraduate journey at New York University - Class of 2028, pursuing a Bachelor's in Mechanical Engineering. His passion for Robotics has led him to create a “Smart Chessboard” that uses voice modulation to help disabled individuals play chess. He is deeply enthusiastic about Coding, Chess, and Horse Riding, all of which inspire and drive his creativity and innovation."
    },
    {
        imgSrc : "/assets/team/ayaan.jpeg",
        name: "Ayaan Kedia",
        designation: "Co-Founder",
        description:"Ayaan is a sports savant, with a passion for squash, Formula 1, and basketball. He enjoys the outdoors, with trekking and camping being his other hobbies."
    },
    {
        imgSrc : "/assets/team/suhani.jpeg",
        name: "Suhani Shah",
        designation: "Co-Founder",
        description:"Suhani is the most gifted dancer in the team, with a passion for aerial gymnastics. This athleticism translates to other sports like football and basketball."
    },
    {
        imgSrc : "/assets/team/ronav.jpeg",
        name: "Ronav Patel",
        designation: "Co-Founder",
        description:"Ronav is a future engineer who builds world-ending robots in his spare time. He also enjoys playing football and basketball."
    },
    {
        imgSrc : "/assets/team/ojas.jpeg",
        name: "Ojas Agasti",
        designation: "Co-Founder",
        description:"Ojas is the Materchef of the team, with a penchant for baking. He is also passionate about Taekwondo and Basketball."
    },
]
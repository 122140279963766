import React, { useState, useEffect } from 'react'
import '../Covid.scss'
import { Container } from 'react-bootstrap'
import AliceCarousel from 'react-alice-carousel'
import ReactPlayer from 'react-player/lazy'

function OnlineSports(props) {
    const [items, setItems] = useState([])
    const mediaPath = "/assets/sports/"

    useEffect(() => {
         
        for(var i=1;i<=8;i++){
            let img = mediaPath + "img" + i + ".jpg"
            setItems((prevImages) => [...prevImages, <img src={img} width="500" height="500" />])
        }
    
        for(var i=1;i<=4;i++){
            let video = mediaPath + "video" + i + ".mp4"
            setItems((prevVideos) => [...prevVideos, <ReactPlayer 
                className='react-player'
                url={video}
                controls 
                width='100%'
                height='100%'
            />])
        }

    }, [])


  return (
    <div className="sports-page">
      <section id="title"><h1>Online Sports</h1></section>
      <section id="activity-section">
        <AliceCarousel 
          autoHeight   
          items={items} 
          onSlideChange = {() => {
              var videoList = document.getElementsByTagName("video");
              for (var i = 0; i < videoList.length; i++) {
                  videoList[i].pause();
              }
          }}
          />
        <Container className="pt-50 data-desc">
            <p>From August 2020 to March 2021, during the lockdown, a team of 5 volunteers, *Dhruv Chauhan, Soham Chauhan, Shaurya Shah, Adya Thanawala and Piaa Santani* conducted online fitness training for the students of Each One Teach One.  Since schools were closed and no one could step out at all, there were hardly any opportunities for the children to manage fitness and sports activities outdoors. This online training gave their energy and morale a great boost as well as gave us time and encouragement to brush up on our own fitness and teaching skills.</p>
            <p>So every Saturday, 9am-10am, we took turns to teach them fitness drills like :</p>
            <ul>
                <li><strong>Cardio activities</strong> - high knees, jumping jacks, butt kicks, front and back hops, etc to increase their stamina.</li>
                <li><strong>Functional training</strong> - squats, lunges, duckwalks, side bends, etc to target specific muscle groups.</li>
                <li><strong>Yoga</strong> - Bhadrasana, Paschimottanasana, Talasana, Ekpadasana, cat and camel pose, snake pose, etc to increase and improve flexibility, concentration and joint mobility.</li>
                <li><strong>Core exercises</strong> - Boat pose, crunches, situps, leg raises, scissors, etc to improve their core strength.</li>
            </ul>
            <p>Each session began and ended with Stretches to warm up and Cool Down the body- like shoulder and neck rotations, knee and hip joint rotations, body twists, arm circles, etc.</p>
            <p>Although we all faced problems like poor network connections, the students were extremely enthusiastic and purposefully followed the instructions and training given to them. It was a thoroughly enjoyable and enriching experience for all of us.</p>
        </Container>
      </section>
    </div>
  );
}

export default OnlineSports
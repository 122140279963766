import React, { useState } from 'react'
import './Contact.scss'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function Contact() {

  const [data, setData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    message: "",    
  });

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://v1.nocodeapi.com/nehaudeshi/google_sheets/ENrfVyABMMuxByYH?tabId=Sheet1" , {
            method : "POST",
            headers : {
              'Content-Type':'application/json'
            },
            body : JSON.stringify([[new Date().toLocaleString(), data.fname, data.lname, data.phone, data.email, data.message ]])  //Here Json stringify uses 2-D array so there are double square brackets
          }
        );
        await response.json();
        setData({
        fname: "",
        lname: "",
        email: "",
        phone: "",
        message: ""})
        alert("Thank You for reaching out to us. We will be contacting you shortly!")
      } 
    catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="contact-page">
      <section id="title"><h1>Contact Us</h1></section>
      <Container>
        <section>
            <Row className="contact-box">
              <Col className="contact-box-1" md={6}>
                <h1>Send a Message</h1>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" id="first_name" name="fname" value={data.fname} onChange={handleChange} required/>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" id="last_name" name="lname" value={data.lname} onChange={handleChange} required/>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control type="tel" id="mobile" name="phone" value={data.phone} onChange={handleChange} required/>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" id="email" name="email" value={data.email} onChange={handleChange} required/>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label>Your Message</Form.Label>
                        <Form.Control as="textarea" rows={3} id="message" value={data.message} name="message" onChange={handleChange} required/>
                      </Form.Group>
                    </Col>
                    <Col xs={12} className="text-center">
                      <Button type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col className="contact-box-2" md={6}>
                <h1>Get in Touch</h1>
                <div class="contact-text">
                    <span><FontAwesomeIcon icon={faEnvelope} /><a href="mailto:khelkatha2019@gmail.com">khelkatha2019@gmail.com</a></span>
                    <span><FontAwesomeIcon icon={faPhoneAlt} /><a href="tel:+919821189885">(+91)-9821189885</a></span>
                    <span><FontAwesomeIcon icon={faPhoneAlt} /><a href="tel:+919821350443">(+91)-9821350443</a></span>
                    <span><FontAwesomeIcon icon={faPhoneAlt} /><a href="tel:+919821139735">(+91)-9821139735</a></span>
                </div>
              </Col>
            </Row>
        </section>
      </Container>
   </div>
  );
}

export default Contact;

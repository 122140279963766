import React from 'react'
import { Card, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ButtonCard = (props) => {
    return(  
        <Col lg>
            <Link to={props.link}>
                <Card className="text-center">
                    <Card.Img src={props.img} alt="Image" />
                    <Card.ImgOverlay className="d-flex">
                        <Card.Title className="align-self-center">
                        {props.text.heading}
                        </Card.Title>
                    </Card.ImgOverlay>
                </Card>
            </Link>
        </Col>
    )
}

export default ButtonCard
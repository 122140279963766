import React, { useState, useEffect } from 'react'
import '../../Covid.scss'
import { Container } from 'react-bootstrap'
import {activities} from '../CounsellingData'
import AliceCarousel from 'react-alice-carousel'
import ReactPlayer from 'react-player/lazy'

function Counselling(props) {
  const [activityData, setActivityData] = useState({description: [""]})
  const [items, setItems] = useState([])
 
  useEffect(() => {
      setActivityData(activities.find(x => x.path === props.match.params.counsellingName))
  },[])

  useEffect(() => {
    if(activityData.mediaPath){
      let imgPath = activityData.mediaPath + "/img"
      let videoPath = activityData.mediaPath + "/video"
      for(var i=1;i<=activityData.imgCount;i++){
        let img = imgPath + i + ".jpg"
        setItems((prevImages) => [...prevImages, <img src={img} width="500" height="500" />])
      }

      for(var i=1;i<=activityData.videoCount;i++){
        let video = videoPath + i + ".mp4"
        setItems((prevVideos) => [...prevVideos, <ReactPlayer 
          className='react-player'
          url={video}
          controls 
          width='100%'
          height='100%'
        />])
      }
    }
  }, [activityData])


  return (
    <div className="activity-page">
      <section id="title"><h1>{activityData.name} - {activityData.activity}</h1></section>
      <section id="activity-section">
        <AliceCarousel 
          autoHeight   
          items={items} 
          onSlideChange = {() => {
              var videoList = document.getElementsByTagName("video");
              for (var i = 0; i < videoList.length; i++) {
                  videoList[i].pause();
              }
          }}
          />
        <Container className="pt-50 data-desc">
            {activityData.description.map(para => <p>{para}</p>)}
        </Container>
      </section>
    </div>
  );
}

export default Counselling
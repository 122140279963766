import React from 'react'
import '../Action.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import {drives} from './DriveData'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

function Drives() {
  return (
   <div className="drive-page">
      <section id="title"><h1>Drives</h1></section>
      <section id="actions-section">
        <Container>
          <p>During the pandemic years of 2020 and 2021, we held few drives where we distributed care packages and activity kits to 200 children of a municipal school. We even sold masks and Diya’s to raise funds to support the drives</p>
          <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2}}>
            <Masonry>
              {drives.map((drive, i) => {
                let blogClass = i%2 == 0 ? "blog-col yellow-bg" : "blog-col blue-bg"
                let link = "./drives/" + drive.path
                return(
                  <Link to={link}>
                    <div className={blogClass}>
                      <img src={drive.imgPath + "/img1.jpg"} alt="drive-img"/>
                      <Row>
                          <Col xs={4} className="blog-date">
                            <h2>{drive.date}</h2>
                          </Col>
                          <Col xs={8} className="blog-heading">
                            <h6>{drive.type}</h6>
                            <h2>{drive.name}</h2>
                          </Col>
                      </Row>
                    </div>
                  </Link>
                )
              })}
          </Masonry>
        </ResponsiveMasonry>
      </Container>
      </section>
   </div>
  );
}

export default Drives;

import React from 'react'
import '../../Participate.scss'
import { Container, Card, Row, Col } from 'react-bootstrap';

function SportsEquipment() {
  return (
   <div className="sports-page">
    <section id="title"><h1>Sports Equipment Donation</h1></section>
     <Container>
        <section id="section1">
            <img src="/assets/misc/equipment.png" style={{width: "100%"}}/>
        </section>
        <section id="section2">
          <Card className="yellow">
            <h3>Get Kids Off the Sidelines By Donating New and Gently-Used Sports Equipment</h3>
            <br/>
            <p>It is our mission to help those less fortunate who love sports but cannot afford the equipment necessary to play. Through play we become stronger individuals, build better communities and create a more positive future. We’ve heard many stories about the positive impact play has had on them. You can help make an impact too by donating what you do not require.</p>
            <p>Instead of having your new or gently used sports equipment take up space in your house, or even worse, end up in a landfill, donate them to Khel Katha and we will give them to those in need.</p>
            <p>Please use your best judgment on the condition of equipment before donating. If you wouldn’t trust your kid using/wearing it, neither will we!</p>
            <p>Please contact us with what you have to donate and we’ll get right back to you.</p>
          </Card>
        </section>
        <section id="section3" className="text-center">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScevxuk5odCyqd9gKhRYcMOrQKztyDcmvrp5QULQmdrsFPA2g/viewform?embedded=true" frameBorder="0" marginHeight="0" marginWidth="0" style={{width:"100%", height:"1000px"}}>Loading…</iframe>        
        </section>
        <section id="section3">
        <Row>
          <Col sm={12} md={6} className="mt-20">
            <Card className="blue" style={{height:"100%"}}>
              <h3>List of Donors</h3>
              <ol>
                <li>Virty Shah</li>
                <li>Ashwini</li>
              </ol>
            </Card>
          </Col>
          <Col sm={12} md={6} className="mt-20">
            <Card className="yellow" style={{height:"100%"}}>
              <h3>Sports Gear Received</h3>
              <ol>
                <li>Volleyball- 9</li>         
                <li>Tennis - 75</li>       
                <li>Cricket - 20</li>        
                <li>Badminton - 49</li>          
                <li>Football- 6</li>       
                <li>Basketball- 3</li>         
                <li>Skipping ropes-10</li>
                <li>Rubber ring-10</li>          
                <li>Chess board- 4</li>            
                <li>Table tennis- 39</li>              
                <li>Squash - 7</li>
              </ol>
            </Card>
          </Col>
        </Row>
        </section>
     </Container>
   </div>
  );
}

export default SportsEquipment;

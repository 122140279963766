import React from 'react'
import '../Participate.scss'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';

function Donate() {
  return (
   <div className="donate-page">
    <section id="title"><h1>Support Us</h1></section>
     <Container>
       <section>
         <Row>
          <Col sm={12} md={6} className="mt-20">
            <Card className="yellow" style={{height:"100%"}}>
              <h3>Bank Transfer</h3>
              Beneficiary name :- Each One Teach One Charitable Foundation 
              ( c/o Khel Katha ) <br/>
              INDIAN OVERSEAS BANK<br/>
              SION (EAST) BRANCH(0542)<br/>
              SALVATION ARMY BUILDING<br/>
              SION ROAD, MUMBAI 400022<br/>
              A/C NO. 054201000018347<br/>
              NEFT/RTGS CODE : IOBA0000542<br/>
            </Card>
          </Col>
          <Col sm={12} md={6} className="mt-20">
            <Card className="blue" style={{height:"100%"}}>
              <h3>Sports Equipment</h3>
              <p>Don’t know what to do with sports equipment that you or your kids no longer use? By donating Sports Equipment to support our mission, you will be helping us to keep putting more kids on the playing field.</p>
              <div className="text-center">
                <Link to="./donate/sports-equipment">
                  <Button>Know More</Button>
                </Link>
              </div>
            </Card>
          </Col>
          <Col sm={12} md={6} className="mt-20">
            <Card className="blue" style={{height:"100%"}}>
              <h3>Corporate Partnerships</h3>
              <p>Sponsorship is a key element of many companies’ marketing. At the same time, companies are increasingly concerned about corporate social responsibility. Corporate charity donations will be making the company brand visible, usually associated with sports events, annual or on going activities and much more. </p>
              <div className="text-center">
                <Link to="./donate/corporate-partnerships">
                  <Button>Know More</Button>
                </Link>
              </div>
            </Card>
          </Col>
          <Col sm={12} md={6} className="mt-20">
            <Card className="yellow" style={{height:"100%"}}>
              <h3>Direct To Community Giving</h3>
              <p> In the wake of COVID-19, We've compiled urgent needs from community leaders helping those whose lives have been affected and providing necessary resources</p>
              <p>Get in Touch to know how you can help too</p>
            </Card>
          </Col>
         </Row>
       </section>
     </Container>
   </div>
  );
}

export default Donate;

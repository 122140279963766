export const sports = [
    {
        "mediaPath": "/assets/events/sports-day/sports-2/",
        "images" : 16,
        "videos" : 0,
        "header": ["Date : 16th Dec, 2023", "Venue : Bal Vikas Manoranjan Maidan"]
    },
    {
        "mediaPath": "/assets/events/sports-day/sports-1/",
        "images" : 18,
        "videos" : 5,
        "header": ["Date : 20th Dec, 2022", "Venue : Bal Vikas Manoranjan Maidan"]
    }
]
import React from 'react'
import '../Covid.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { online_activites } from './ActivitiesData'
import AliceCarousel from 'react-alice-carousel';
import Activity from './Activity';

function OnlineActivities() {
  return (
   <div className="online-activities-page">
    <section id="title"><h1>Online Activities</h1></section>
    <Container>
        <section>
          <p>Since we couldn’t go to the schools physically during the pandemic we decided to  virtual connect with the students and keep them busy. We formed a WhatsApp group where we organised the following activities monthly</p>
          <Row>
          {online_activites.map((activity,i) => {
            return(
              <Col md={6} className="mb-20">
                <Activity activity= {activity} bg={(i%4 != 0 && i%4 != 3) ? "yellow" : "blue"}/>
              </Col>
            )
          })
        }
          </Row>
        </section>
      </Container>
   </div>
  );
}

export default OnlineActivities;

export const activities = [
    {
        mediaPath : "/assets/online_activities/counselling/project-sapna",
        name: "Project Sapna",
        imgCount: 3,
        videoCount: 0,
        path: "project-sapna",
        description: ["Khel Katha, along with Vocational training, wants to help students of the municipal school in understanding their career path. For this we partnered with Project Sapna, which is an online platform for career Councelling.", "‘Project Sapna’ hopes to bridge the gap of information disparity by providing a platform (http://projectsapna.org/), available in both English and Hindi, for students to access important information that they would otherwise not be able to through interviews from established professionals in various fields. This is done with the intention of allowing for nuanced insights into multiple professions, coupled with the steps necessary to potentially pursue one of these careers.","The aptitude test and algorithm helps direct students to suitable careers based on their implied interests, skills and personality traits, is the starting point. Building on that, students have the option to either explore one of the recommended professions or choose another that interests them, which allows them to consider professions that they may not have known they had an aptitude for.", "Project Sapna did its beta launch with the municipal school students of Each One Teach One with 15 children helping them over a zoom demo and a WhatsApp group chat. Post the beta launch, Khel Katha worked with Project Sapna to add an aptitude test to the website following which we are expanding Project Sapna to other EOTO schools."]
    },
    {
        mediaPath : "/assets/online_activities/counselling/round-year",
        name: "Round the Year",
        imgCount: 2,
        videoCount: 0,
        path: "round-year",
        description: ["We started Vocational Training for underprivileged children aged from 14 to 16 years. Every month we taught them different activities such as dance, yoga, mehendi, soap making, etc. We observed a lot of interest and keenness in the children to learn these activities. Therefore we decided to shortlist children who showed immense dedication and talent to further their learning of these skills. We wanted to help the kids make a career out of these skills. To understand the real passion within these students, we conducted counselling sessions with the selected few. To know their family background and interests we asked them questions such as their parents’ profession, their interests and hobbies, and whether they are keen on further enhancing these skills.","Under the dance activity, we counselled 9 kids out of which 8 committed to learn further and take up dance professionally. Some were keen on becoming dance teachers, some were keen on becoming performers and the rest wanted to be choreographers.", "Under the yoga activity, we counselled 11 kids out of which 7 committed to learn further and take up yoga professionally. These 7 showed genuine interest in becoming yoga teachers."]
    },
]
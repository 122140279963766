import React from 'react'
import '../Action.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import {vocationals} from './VocationalTrainingData'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

function VocationalTraining() {
  return (
   <div className="vocational-page">
      <section id="title"><h1>Vocational Training</h1></section>
      <section id="actions-section">
        <Container>
          <p>Each One Teach One has been imparting education in municipal schools across Maharashtra for decades. Recognizing that not all children are inclined towards academics, they, along with Khel Katha, have initiated vocational training programs. These activities are conducted online to maximize outreach to schools throughout Maharashtra. Students are taught skills such as Mehendi, fabric painting, zentangle, best out of waste, soap and candle making, and dancing. Professional instructors are employed for these classes, and Khel Katha provides the necessary materials. Classes are held on Saturdays, with a new skill introduced each month. Talented children are identified and supported to further pursue their interests. The aim is to promote financial independence among these children and expose them to diverse avenues for earning a livelihood.</p>
          {vocationals.map((vocational, i) => {
            const images = []
            for(let i = 1; i <= 4; i++){
              images.push(<img src={`${vocational.imgPath}/img${i}.jpg`} alt="sports-training-img" height="200"/>)
            }

            return (
            <div style={{padding: "16px 0"}}>
              <h4>{vocational.name}</h4>
              <ResponsiveMasonry columnsCountBreakPoints={{350: 2, 750: 4}}>
                <Masonry>
                  {images}
                </Masonry>
              </ResponsiveMasonry>
            </div>
            )})}
      </Container>
      </section>
   </div>
  );
}

export default VocationalTraining;

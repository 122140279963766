import React, { useState, useEffect } from 'react'
import '../Goal.scss'
import { Container, Card } from 'react-bootstrap';
import AliceCarousel from 'react-alice-carousel';

function Eoto() {
  const [items, setItems] = useState([])

  useEffect(() => {
    const imgPath = "/assets/eoto/img"
    for(var i=1;i<=10;i++){
      let img = imgPath + i + ".jpg"
      setItems((prevImages) => [...prevImages, <img src={img} width="1800" height="500" />])
  }
  },[])

  return (
    <div className="eoto-page">
      <section id="title"><h1>Each One Teach One</h1></section>
      <Container>
        <section id="section1">
          <AliceCarousel 
            items={items}
            autoPlay
            autoPlayInterval="2000"
            infinite
            disableDotsControls
          />
        
          <Card>

            <p>Each One Teach One (EOTO) Charitable Foundation is a developmental organization established in 1983. It aims at creating opportunities for underprivileged children in India. Over the past four decades they have reached out to 10,000+ children in urban and rural India. Mrs. Jyoti Tanna is the founder of EOTO.</p>

            <p>The primary aim is to educate the weaker section of the society. Extra coaching classes are held for those students who find it difficult to cope with the regular pace of education. Computer Education is given to the children by setting up a computer Lab and appointing a trainer. A Toys and Books Library is provided in schools to broaden the general knowledge of the children.  EOTO sets up Science laboratories in schools which allow hand on experience on the technical subjects.</p> 

            <p>EOTO Scholarships are given to encourage their students to study further. Additionally, the organisation shoulders the responsibilities by providing uniforms and notebooks to the students. A career guidance program after 10th standard directs the children based on their aptitudes ensuring that they complete their education. Workshops are organised on personality development and leadership skills which help the students to keep up with the demands of the real world.</p>

            <p>Teacher training programs are organised to strengthen the teachers with better and more enhanced teaching techniques. To make the school life more interesting, indoor activities like carom, chess, art craft, rangoli competition are facilitated. Moreover, outdoor sports, sports day and annual picnics are organised. Health camps are held to help identify various primary health concerns.</p>

            <p>EOTO is committed to enhancing the schooling experience of underprivileged children by strengthening their intellectual capabilities and competencies. It works towards training them to become socially responsible citizens of India.</p>

          </Card>

        </section>
      </Container>
    </div>
  );
}

export default Eoto;

import React from 'react'
import '../Participate.scss'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import {donors1, donors2, donors3} from './DonorsData'
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'

function DonorModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.data.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {props.data.description}
        </p>
        {props.data.html?props.data.html:null}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Donors() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState({name: "", description: ""})

  return (
   <div className="donors-page">
    <section id="title"><h1>Donors</h1></section>
    <Container>
       <section>
          <Row>
           {donors1.map((donor, i) => {
            let blogClass = i%2 != 0 ? "yellow p-0" : "blue p-0"
              return(
                <Col md={3} xs={6} className="mb-20">
                    <Link to={"./donors/" + donor.path}>
                      <Card className={blogClass} style={{"height": "100%"}}>
                          <Card.Img src={donor.logoPath} style={{"height": "150px"}}/>
                          <Card.Body>
                              <Card.Title>{donor.name}</Card.Title>
                          </Card.Body>
                      </Card>
                    </Link>
                </Col>
              )
            })}
           </Row>
           <hr/>
          <Row>
           {donors2.map((donor, i) => {
            let blogClass = i%2 != 0 ? "yellow p-0" : "blue p-0"
              return(
                <Col md={3} xs={6} className="mb-20">
                    <Card className={blogClass} style={{"height": "100%", "cursor": "pointer"}} onClick={() => {setModalShow(true); setModalData(donor)}}>
                        <Card.Img src={donor.logoPath} style={{"height": "150px"}}/>
                        <Card.Body>
                            <Card.Title>{donor.name}</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
              )
            })}
           </Row>
           <hr/>
           <Row>
           {donors3.map((donor, i) => {
            let blogClass = i%2 != 0 ? "yellow p-0" : "blue p-0"
              return(
                <Col md={3} xs={6} className="mb-20">
                    <Card className={blogClass} style={{"height": "100%"}}>
                        <Card.Img src={donor.logoPath} style={{"height": "150px"}}/>
                        <Card.Body>
                            <Card.Title>{donor.name}</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
              )
            })}
           </Row>
       </section>

       <DonorModal 
        show={modalShow}
        data={modalData}
        onHide={() => setModalShow(false)}
        />
    </Container>
   </div>
  );
}

export default Donors;

import React, { useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom';

function App(props) {

  const prevLocationRef = useRef();
  useEffect(() => {
    prevLocationRef.current = props.location;
  });
  const prevLocation = prevLocationRef.current;

  useEffect(() => {
    if (props.location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }, [props.location])

  return (
   <div>
     {props.children}
   </div>
  );
}

export default withRouter(App);

import React from 'react'
import '../Action.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { activities } from './ActivityData'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

function Activities() {

  const images = []
  for(let i = 1; i < 18; i++){
    images.push(<img src={`/assets/activities/img${i}.jpg`} alt="sports-training-img"/>)
  }

  return (
   <div className="activity-page">
     <section id="title"><h1>Sports Training</h1></section>
      <section id="actions-section">
        <Container>
        <p>In 2019, we forged a partnership with a municipal school in Mumbai, dedicating our Saturdays to teaching sports. Our dedicated volunteers impart skills in basketball, football, chess, and more, providing the children with necessary equipment to enhance their play. Each week, 6 to 8 volunteers engage the students with icebreaker games, fostering comfort and familiarity. We orchestrate dynamic activities that make Saturdays eagerly anticipated—a refreshing break from studies where children not only learn but also have fun.</p>
        <p>Furthermore, we celebrate cultural festivals such as Diwali and Christmas with great enthusiasm. Festivities include distributing sweets and gifts, and during Christmas, Santa Claus delights the children with playful interactions and chocolate treats.</p>
        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2}}>
          <Masonry>
            {images}
        </Masonry>
      </ResponsiveMasonry>
      </Container>
      </section>
   </div>
  );
}

export default Activities;

// With Page
export const donors1 = [
    {
        logoPath : "/assets/donors/donor1_5.jpg",
        imgPath : "",
        path : "evio",
        name: "EVIO Private Limited",
        description: ["EVIO Private Limited (EVIO) is your Automation, Electrical and Instrumentation solutions partner that you can depend on for your challenging Electrical and Automation projects.", "Prior to incorporation of EVIO, the promoters of EVIO were involved in execution of several turnkey construction contracts in major parts of India, in their capacity as the promoters and directors of Rohini Industrial Electricals Private Limited.", "As a part of business strategy the promoters of EVIO sold their stake in Rohini to Voltas Limited ( A Tata Group Company), in 2008.", "Evio was established in 2011 and since then we have been doing several large Electrical and Automation Projects. We had a turnover of Rs.170 Cr. in previous year and are a team of more than 400 employees. Our Head office is at Mumbai and full fledge branch offices at Pune & Bangalore.", "We offer following services Pan India on EPC basis are :"],
        html: <div><ol><li>Electrical & Instrumentation Turnkey Solutions</li><li>Industrial Automation</li><li>Building Automation</li></ol><p>We provide services to following Industries / sectors.</p><table border="1"><tr><td>Automobiles, Tyre & Ancillary</td><td>Data Centre & IT Park</td></tr><tr><td>Food, Pharmaceutical & FMCG</td><td>Corporate & Commercial complex</td></tr><tr><td>Oil & Gas</td><td>Hotels & Hospital</td></tr><tr><td>Chemical, Textile & Cement</td><td>Infrastructure Project – Ware House & Airport</td></tr><tr><td>Water Treatment</td><td>High-end Residential Apartments & Villa</td></tr><tr><td>Steel, Metals & Engineering</td><td>Institutional Buildings</td></tr></table><br></br><p>Our few of major client are Reliance, MRF, Hindustan Coca Cola, Cummins, Net magic, Tata Motors, Instakart, Mahindra & Mahindra, ST Tele media etc. We earnestly request you to give us an opportunity to work with you on your prestigious projects. For further any information please visit our web site.</p></div>
    },
    {
        logoPath : "/assets/donors/donor1_3.jpg",
        imgPath : "/assets/donors/goradia",
        path : "goradia",
        name: "Abigail Goradia's Premium 4 Star Hotel",
        description: ["Shirdi Address: Off Pimplawadi Road, Laxmibai Shinde Marg, Opp. Sai Baba temple’s Gate No. 2, Shirdi (Maharashtra) -423109", "ABIGAIL Goradia's is a 4 Star premier hotel combining luxury with serenity located on footsteps of Shri Sai Baba temple. Situated on the Off Pimpalwadi Road, the hotel offers easy access to highways and to entry and exit points. The hotel is less than 100 meters away from Shri Sai Baba temple, One Km away from bus stand and Shirdi railway station, 75 kms from Shani Shingnapur. Shirdi is a major pilgrimage as well as leisure destination and people visit the city throughout the year because of Shri Sai Baba.","The location of ‘Hotel Goradia’s’ is at Off Pimpalwadi Road, Shirdi (Maharashtra) and Hotel is just Hop, Skip and Jump away from Famous Shirdi Sai Baba Temple & a walking distance from Nagar-Manmad highway, Wet-N-Joy Water Park.","80- Keys 4 Star hotel - State of Art with Star Ambience – Located at a walking distance from Shri SAI SABA Temple"]
    },
    {
        logoPath : "/assets/donors/donor1_4.jpg",
        imgPath : "",
        path : "chesterfield",
        name: "Chesterfield Furniture",
        description: ["Premium Interior Furniture Manufacturing Company for over 20 years.","Address: 2nd Floor, Tree House Building, above Mercedes, Raghuvanshi Mills Compound Lower Parel West Mumbai - 400013 ", "Chesterfield furniture begins with the utmost attention place on the Interiors and the customers need for an unparalleled image and true practicality, Chesterfield Furniture offers the art of Home-Living by focusing on attention to details and materials making each creation unique and inimitable. Each unique piece of furniture manufactured is not only an aesthetic masterwork, it is engineered to sustain many years of regular use.","Beginning with design, where harmony, proportions and colours are carefully studied, the concern is the same: QUALITY. In every phase of production, we love to transform the initial idea into exclusive piece of furniture, taking the mood of the furnishing into careful consideration, as well as the high craftsmanship required to make it all happen – we know that even before that we will need the mind, hands and heart. This is then followed by selection of exclusive materials required for each masterpiece.", "We create large furnishing projects for Luxury Apartments, Prestigious villas and home offices, all over the country. Chesterfield Furniture is an actual working method in which you can find concrete solutions for your every requirement.","We look at every project and every piece of furniture as an individual sculpture build with love in INDIA "]
    },
    {
        logoPath : "/assets/donors/donor1_1.jpg",
        imgPath : "",
        path : "winjit",
        name: "Winjit Technologies Pvt. Ltd.",
        description: ["Winjit is a fast-growing provider of software consultancy, design and development services, headquartered in India. Winjit has a proven track record of successful development and implementation of solutions on different technologies for a variety of customers. With more than 15 years of undertaking technical challenges and building robust AI solutions, Winjit establishes meaningful and ingenious strategies, maps curative solutions to help stand out within the automation industry.", "Winjit helps to design and develop agile solutions that range from IoT solutions to a wide range of multiple artificial intelligence-based platforms strategically crafted to specific requirements. Through the wide range of experiences that Winjit has incurred over the years, it has become one of India’s major tech authorities in the field of artificial intelligence and automation.", "With state-of-the-art delivery centers in India, Winjit has a team of competent professionals with expertise in different technologies. To maximize value for the customer, Winjit’s development centers follow ISO 9001 certified processes."]
    },
    {
        logoPath : "/assets/donors/donor1_2.jpg",
        imgPath : "",
        path : "ganpatroy",
        name: "Ganpatroy Pvt. Ltd.",
        description: ["Our parent company Gunpatroy Pvt. Ltd. traces inception back to its antecedents and is as old as the mica industry and was incorporated under the Indian Companies Act on February 5th, 1930. Another subsidiary company was formed in the name of Shree GR Exports Pvt. Ltd. and was established on August 6th, 1993 with a view to diversify part of the mica business and to meet the pressing demands of modern industry.", "We have been consistently exporting Mica to many parts of the world mainly Japan, China, USA, UK and some Middle Eastern countries. We are an ISO 9001:2015 and SA 8000 certified company having an annual turnover of over USD 7 million.", "Our processing units are based in Jharkhand & Rajasthan employing a work force of over 100 members.", "We primarily manufacture and export Mica namely Mica Scrap, Mica Flakes, Mica Powder, Mica Blocks, Mica Splittings, Fabricated Mica, Micanite, Mica Paper and related products.", "Progress and leadership for over a century  means reputation built of tradition, patient labour and helpful advice to customers the world over. Today, under generations of the famed Rajgarhia family, our companies still carry the simple but honourable statement “Service To Satisfy” and our Directors, Managers and Workers all strive to that purpose."]
    }
]

// With Modal
export const donors2 = [
    {
        logoPath : "/assets/donors/donor2_5.jpg",
        name: "Plastibends India Ltd",
        description: "Plastiblends India Limited is India's largest manufacturer and exporter of Colour & Additive Master Batches and Thermoplastic Compounds for the Plastic Processing Industry. The first Master Batch Company with the status of an Export House by Government of India. Awarded the highest recognition for exports, the prestigious Certificate as “Largest  Exporter of Master Batches” from India by the PLEX COUNCIL (Plastic Export Promotion Council, Government Of India).",
    },
    {
        logoPath : "/assets/donors/donor2_4.jpg",
        name: "M. J. Foundation",
        description: "M. J. Foundation is a Charitable Trust run and managed by M J Group. The Trust is carrying on following charitable activities:",
        html: <ol><li>Educational:  Providing free education aid to the needy students and also contributing by donations to the educational institutions to promote the education in the Society.</li><li>Medical: Providing free Medical aid to the needy patients suffering from any disease or accidents and creating awareness of Diabetes and its effect through focused information and education.</li><li>Relief of poverty:  Providing financial aid to the poor or deserving persons and Distributing free food and clothes to the needy and poor by contributing through donations to the institutions.</li></ol>
    },
    {
        logoPath : "/assets/donors/donor2_1.jpg",
        name: "Mapro",
        description: "Mapro, Mahabaleshwar, generously donated 300 fruit crushes and 200 packets of jelly cubes."
    },
    {
        logoPath : "/assets/donors/donor2_2.jpg",
        name: "Gear Up Sports",
        description: "Jitinbhai from Gear-Up Sports donated 35 pairs of sports shoes which was given to the children at school."
    },
    {
        logoPath : "/assets/donors/donor2_3.jpg",
        name: "Fusion Sports and Fitness",
        description: "Kashish Shah from Fusion Sports and Fitness donated 15 pairs of sports shoes which was given to the children at the school."
    }
]

// Only Logo
export const donors3 = [
    {
        logoPath : "/assets/donors/donor3_1.jpg",
        name: "Viz Technologies Pvt. Ltd.",
    },
    {
        logoPath : "/assets/donors/donor3_2.jpg",
        name: "Confisys Solutions LLP",
    },
]




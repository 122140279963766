import React from 'react'
import {NavDropdown, Navbar, Nav} from 'react-bootstrap'

function Header() {
  return (
    <Navbar bg="dark" variant="dark" expand="xl" fixed="top" >
      <Navbar.Brand href="/"><img src="/kk_logo.png" className="ml-30" style={{width: "80px"}}/></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="justify-content-center" style={{width: "100%"}}>
          <Nav.Link href="/">Home</Nav.Link>
          
          <NavDropdown title="Goal" id="basic-nav-dropdown">
            <NavDropdown.Item href="/goal/objective">Objective</NavDropdown.Item>
            <NavDropdown.Item href="/goal/story">Our Story</NavDropdown.Item>
            <NavDropdown.Item href="/goal/eachoneteachone">Each One Teach One</NavDropdown.Item>
          </NavDropdown>
          
          <NavDropdown title="Team" id="basic-nav-dropdown">
            <NavDropdown.Item href="/team/founders">Founders</NavDropdown.Item>
            <NavDropdown.Item href="/team/team">Team</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Action" id="basic-nav-dropdown">
            <NavDropdown.Item href="/action/drives">Drives</NavDropdown.Item>
            <NavDropdown.Item href="/action/sportstraining">Sports Training</NavDropdown.Item>
            <NavDropdown.Item href="/action/vocationals">Vocational Training</NavDropdown.Item>
          </NavDropdown>
          
          <NavDropdown title="Covid Times" id="basic-nav-dropdown">
            <NavDropdown.Item href="/covid-times/vocational">Online Vocational</NavDropdown.Item>
            <NavDropdown.Item href="/covid-times/activities">Online Activities</NavDropdown.Item>
            <NavDropdown.Item href="/covid-times/teaching">Online Teaching</NavDropdown.Item>
            <NavDropdown.Item href="/covid-times/counselling">Online Counselling</NavDropdown.Item>
            <NavDropdown.Item href="/covid-times/sports">Online Sports</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Events" id="basic-nav-dropdown">
            <NavDropdown.Item href="/events/sports-day">Sports Day</NavDropdown.Item>
            <NavDropdown.Item href="/events/mela">Mela</NavDropdown.Item>
          </NavDropdown>
          
          <NavDropdown title="Gallery" id="basic-nav-dropdown">
            <NavDropdown.Item href="/gallery/photos">Photos</NavDropdown.Item>
            <NavDropdown.Item href="/gallery/videos">Videos</NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Participate" id="basic-nav-dropdown">
            <NavDropdown.Item href="/participate/donate">Donate</NavDropdown.Item>
            <NavDropdown.Item href="/participate/volunteer">Become a Volunteer</NavDropdown.Item>
            <NavDropdown.Item href="/participate/donors">Donors</NavDropdown.Item>
          </NavDropdown>
          
          <Nav.Link href="/contact">Contact</Nav.Link>
        
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;

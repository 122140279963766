export const activities = [
    {
        mediaPath : "/assets/online_activities/vocational/january-22",
        name: "January 2022",
        activity: "Fabric Painting",
        imgCount: 21,
        videoCount: 0,
        path: "january-22",
        description: ["In the month of January the children were taught Fabric painting by Ms Zankhana. She is a very good teacher. Taught the children all about fabric painting and the care that has to be taken. There were total 70 kids who took part in it. Khel Katha provided the kit for the fabric painting. The kit included Fabric paints, brushes, printed hankies and pillow cover on which they learned how to paint. We even gave them a ring to hold the cloth tight. They were taught before and after care of the cloth before painting on it. They painted beautiful butterflies and birds on fabric pieces we gave them. The teacher taught them how to shade the figures on the fabric. They even learnt to paint on material like Jeans. In the last class they were taught Tie n Dye.", "To our surprise the children were very enthusiastic and thoroughly enjoyed the class. We got very good feedback from the kids as to how much they learned and enjoyed. They were so good that we gave certificates to almost 45 children. This was the last class for the year 21-22. Now the children have to prepare for their final exams. We will resume the Vocational training from July."]
    },
    {
        mediaPath : "/assets/online_activities/vocational/december-21",
        name: "December 2021",
        activity: "Candle Making",
        imgCount: 14,
        videoCount: 1,
        path: "december-21",
        description: ["In the month of December, we held Candle making class. There were a total of 75 students to whom Khel Katha  sent material like wax, decorations and containers etc for making the candles. ", "TThe Children were divided into 2 groups. Group 1 was taught by Ms Pushpa Achra & Group 2 by Ms Dia Chimnani. The classes were held on the first 3 Saturdays of December. Kids were taught to make paraffin & gel wax candles. They made candles in different shaped glasses & in moulds.  Teachers taught them how to decorate the candles. The students made some beautiful pieces & were very excited to learn the procedure. Around 70-75  students participated in both the groups. Overall it was a good learning & the students can make these beautiful candles & even make a living out of this."]
    },
    {
        mediaPath : "/assets/online_activities/vocational/november-21",
        name: "November 2021",
        activity: "Zentangle",
        imgCount: 26,
        videoCount: 0,
        path: "november-21",
        description: ["In the month of November, we taught the children a different form of art called Zentangle. 65 children took part in the activity. Ms Zainab taught them very nicely and patiently. For this activity we provided the children with a Micron pen, tiles, drawing book, pen and pencil. We even gave them a few envelopes and a diary. The idea behind giving this was to make them realise that tangles can be done on different surfaces to make them look pretty. Then they can sell these products also.", "The teacher taught them a total of10 tangles and showed them how they can be used in different combinations. We were impressed seeing the pictures they sent. The children were very bright to pick this art form so quickly. Out of 65 children we gave certificates to 27 children. We felt that 9 children were very talented and we can hopefully encourage them to learn Zentangle further."]
    },
    {
        mediaPath : "/assets/online_activities/vocational/october-21",
        name: "October 2021",
        activity: "Mehendi",
        imgCount: 8,
        videoCount: 0,
        path: "october-21",
        description: ["In October we conducted Mehendi applying Classes. We had an overwhelming response for this class. Around 75 kids registered. Therefore we had to divide the children in two groups. We appointed two teachers. We gave the children mehendi cones, laminated hand sheets on which they could practice and 12 different sheets of mehendi designs. They enjoyed the Arabic form of applying mehendi. After practising for a few times on paper, they were soon very confident and started applying on their hands directly. The enthusiasm to learn mehendi among the children was unbeatable. They sent lovely pictures to us of their mehendi designs. We then identified children who showed immense talent. Around 30 children had the potential to excel their art."]
    },
    {
        mediaPath : "/assets/online_activities/vocational/september-21",
        name: "September 2021",
        activity: "Phenyl and Soap Making",
        imgCount: 13,
        videoCount: 2,
        path: "september-21",
        description: ["In the month of September we held three classes. In the first class pheny was taught. It was a very simple procedure. The children learnt it fast. They are excited about making it at home and hopeful to  sell it also. Then we taught them soap making for two classes. For both phenyl and soap classes we had procured the material in advance and individually packed them and send it to EOTO. They then distributed to all the children. The children were very happy to learn how to make soaps. They made different kinds using different materials. They send pictures of the soaps they made. All the children did a good job. Few children want to take it up professionally also. In total 45 children took these classes."]
    },
    {
        mediaPath : "/assets/online_activities/vocational/august-21",
        name: "August 2021",
        activity: "Yoga",
        imgCount: 8,
        videoCount: 4,
        path: "august-21",
        description: ["In the month of August we organised Yoga classes which were held every Saturday for the entire month. They were taught by 3 experienced teachers. A total of 61 children participated. They were divided in two groups so that the teachers could give each child proper attention. The teachers taught them different asanas like Surya Namaskar, Pranayam, breather exercises with a straw, Nadi Shodhan and many more. The children showed a lot of sincerity and practiced their asanas before each class. They even sent us their practice videos. After observing them for the entire month we gave certificates to 35 deserving children who showed potential to go further."]
    },
    {
        mediaPath : "/assets/online_activities/vocational/july-21",
        name: "July 2021",
        activity: "Dancing",
        imgCount: 8,
        videoCount: 1,
        path: "july-21",
        description: ["We started the vocational training programme in July 2021 with dance classes for the kids every Saturday for a month. The children danced to the likes of Bollywood songs and showed immense dedication and enthusiasm. A total of 68 kids participated and to celebrate their commitment we gave certificates to 26 of the most dedicated kids. From this we shortlisted 13 talented kids who we felt have the potential to become good dancers after getting trained at vocational centres."]
    }
]
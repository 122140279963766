import React from 'react'
import '../Goal.scss'
import { Container, Row, Col, Card } from 'react-bootstrap'

function Objective() {
  return (
   <div className="objective-page">
    <section id="title"><h1>Our Objective</h1></section>
    <Container>
      <section id="section1" className="pb-0">
        <p className="text-center">Khel Katha is a not for profit organization led by students, dedicated to providing underprivileged children with the resources and opportunities needed to engage in sports. Khel Katha also offer them online vocational training in various skills, empowering them and enhancing their chances of earning a livelihood. Due to limited resources or awareness, many of these children often miss the chance to explore new sports or acquire new skills. Our objective is to enhance the lives of these children and boost India's profile in global sports, while also equipping them with vocational skills to support their financial independence. Currently, we are facilitating sports education at the Vile Parle Municipal school in collaboration with Each One Teach One.</p>
      </section>
      <section id="section2">
        <Card className="blue">
          <Row>
            <Col md={6} sm={12}>
              <img src="/assets/goal/mission.webp" style={{width: "100%"}}/>
            </Col>
            <Col md={6} sm={12} style={{margin: "auto"}} className="text text-right">
              <h3>Our Mission</h3>
              <p>At Khel Katha, our goal is to ensure that every child has the chance to engage in sports, acquire new skills, and have a platform to pursue them professionally. Impacting the lives of these children, ‘One child at a time’, is the Khel Katha mission.</p>
            </Col>
          </Row>
        </Card>
      </section>
      <section id="section3">
        <Card className="yellow">
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} style={{margin: "auto"}} className="text">
              <h3>Our Vision</h3>
              <p>Presently active in 22 schools, our aspiration is to expand our reach to as many as 1200 municipal schools across Mumbai.</p>
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
              <img src="/assets/goal/vision.jpeg" style={{width: "100%"}}/>
            </Col>
          </Row>
        </Card>
      </section>     
    </Container>
   </div>
  );
}

export default Objective;

import React from 'react'
import '../Covid.scss'
import { Container, Card } from 'react-bootstrap';

function OnlineTeaching() {
  return (
   <div className="online-teaching-page">
    <section id="title"><h1>Online Teaching</h1></section>
    <Container>
      <section id="section1">
        <img src="/assets/online_activities/online_teaching.jpeg" style={{width: "100%"}}/>
      </section>
      <section id="section2">
          <Card className="yellow">
            <p>One of the adverse effects of covid was the shutdown of schools, which meant that students were forced to have an online education. While this was hard on all students, the brunt of this decision was felt by those studying in government schools. This was mainly due to the fact that neither the teachers, nor the students possessed the tools to traverse through this difficult period. </p>
            <p>Looking at this situation, Khel Katha stepped in by offering one-on-one classes for all students from the government school. These classes were geared towards Maths and English for students of class 6th to 10th.</p>
            <p>In order to teach such a large set of students one-on-one, there would have to be a large set of people willing to volunteer their time to teach. Khel Katha achieved this by approaching friends, family, older students and even the Rotary Club to ask for volunteers. To bridge any gap due to technological limitations, Khel Katha also provided internet services to various students who did not have wifi at their homes.</p>
            <p>These one-on-one sessions were a huge success, with teachers providing personalised education to each child, and students being able to ask questions, solve doubts, and learn more efficiently. Students and teachers could also plan their sessions to their availability since no one else was involved. Most sessions were held twice a week for a couple of hours. The minutes of every session were logged, so that Khel Katha was updated on what every student was learning. These sessions went on for half a year, between September 2020 and January 2021. This teaching program, which started as a way to help these children out, ended up becoming much bigger than anyone at Khel Katha ever imagined. Through the tireless help of all the volunteers, as well as the neverending enthusiasm of the children, this project became on of the cornerstones in Khel Katha’s Covid relief endeavors.</p>
          </Card>
      </section>
    </Container>
   </div>
  );
}

export default OnlineTeaching;

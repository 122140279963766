export const core = [
    {
        imgSrc : "/assets/team/dhruv.jpg",
        name: "Dhruv Chauhan",
        designation: "Co-head, Sports Division",
        description:"A naturally versatile sports person and martial artist, Dhruv is most passionate about football, hiking and skiing. He is also a wildlife enthusiast and photographer. As a committed changemaker, he has used his robotics skills to help manual scavengers and to clean water bodies, and even summitted Mt.Kilimanjaro to fund Khel Katha initiatives!"
    },
    {
        imgSrc : "/assets/team/soham.jpg",
        name: "Soham Chauhan",
        designation: "Co-head, Vocational training",
        description:"Soham is a trained Capoerista, passionate footballer and an avid mountaineer. As a music lover, the violin is his favorite companion. Always driven towards helping society, his initiatives have taken him from building robot prototypes for BMC workers to organising rural clean up drives, and even to the roof of Africa !"
    },
    {
        imgSrc : "/assets/team/shaurya2.jpeg",
        name: "Shaurya Shah",
        designation: "Co-head, Sports Division",
        description:"Shaurya is a dynamic leader with a passion for sports, bringing years of experience in football and an extensive background in martial arts.He has also won a silver medal in world karate tournament .As a seasoned athlete, Shaurya combines discipline and strategic insight to foster growth and inclusivity in the sports programs at Khelkatha. Since taking on the role of Co-Head, Shaurya has spearheaded initiatives that emphasize skill development, teamwork, and resilience, ensuring students benefit from a holistic approach to sports training. His dedication to accessible, community-focused sports education has made a lasting impact, inspiring students to strive for excellence both on and off the field."
    },
    {
        imgSrc : "/assets/team/adya.jpg",
        name: "Adya Thanawala",
        designation: "Co-Head, Vocational Training",
        description:"Adya is an exceptional aerialist, a bronze world champion in Karate with over a decade of experience, a black belt, and a dedicated baker. Her diverse expertise makes her a well-rounded and influential leader, contributing actively to the vocational and sports initiatives at Khelkatha since 2020. As Co-Head, Adya collaborates closely with her team to build skill-building programs and develop accessible, resourceful strategies for students. Her work includes identifying easily sourced materials and crafting marketable skills, ensuring students receive a practical and empowering learning experience."
    }
]

export const team = [
    {
        imgSrc : "/assets/team/radha.jpg",
        name: "Radha Doshi",
        description:"Radha is a talented footballer, winning the ISSO National Under-19 Girls Football Tournament twice. She's dedicated a decade to perfecting Odissi dance and reached Level 3 in Aerial silk and hoop. In her free time, she enjoys snorkeling ,reading and knitting."
    },
    {
        imgSrc : "/assets/team/advait.jpg",
        name: "Advait Berde",
        description:"Advait is a black belt in karate and a dedicated football player and watcher. In his free time, he indulges in reading novels and watching movies."
    },
    {
        imgSrc : "/assets/team/srihari.jpg",
        name: "Srihari Subramanian",
        description:"Srihari is a badminton player, mathematician with a passion for programming and Lego building. He enjoys travelling, international cuisines and rock music."
    }
]

export const volunteers = [
    {
        name: "Tia Katharani"
    }, 
    {
        name: "Awantika Chaudhry"
    },
    {
        name: "Tyisha Golechha"
    }, 
    {
        name: "Vritti Tulsiyan"
    }, 
    {
        name: "Maanasvi Rajwani"
    }, 
    {
        name: "Maanasvi Rajwani"
    }, 
    {
        name: "Reya Kejriwal"
    }, 
    {
        name: "Krisha Savla"
    }
]
import React, { useState, useEffect } from 'react'
import '../Gallery.scss'
import { Container } from 'react-bootstrap'
import ReactPlayer from 'react-player/lazy'

function Videos() {

  return (
   <div className="video-page">
      <section id="title"><h1>Videos</h1></section>
      <Container>
        <section id="section1" className="text-center">
          <div class="video-div">
            <h3>Wow Moments</h3>
            <ReactPlayer 
            className='react-player'
            url="/assets/gallery/videos/video1.mp4"
            controls 
            width='100%'
            height='100%'/>
          </div>
          <div class="video-div">
            <h3>Football Training</h3>
            <ReactPlayer 
            className='react-player'
            url="/assets/gallery/videos/video2.mp4"
            controls 
            width='100%'
            height='100%'/>
          </div>
          <div class="video-div">
            <h3>Student Teacher Testimonials</h3>
            <ReactPlayer 
            className='react-player'
            url="/assets/gallery/videos/video3.mp4"
            controls 
            width='100%'
            height='100%'/>
          </div>
          <div class="video-div">
            <h3>Diwali Party</h3>
            <ReactPlayer 
            className='react-player'
            url="/assets/gallery/videos/video4.mp4"
            controls 
            width='100%'
            height='100%'/>
          </div>
          <div class="video-div">
            <h3>Fun with Santa</h3>
            <ReactPlayer 
            className='react-player'
            url="/assets/gallery/videos/video5.mp4"
            controls 
            width='100%'
            height='100%'/>
          </div>
        </section>
      </Container>
   </div>
  );
}

export default Videos;

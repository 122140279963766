import React, { useState, useEffect } from 'react'
import '../Gallery.scss'
import { Container, Row, Col } from 'react-bootstrap'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

function Photos() {
  const [items, setItems] = useState([])

  useEffect(()=>{
    setItems([])
    for(let i=1; i<40; i++){
      setItems((prevImgs) => [...prevImgs, "/assets/gallery/images/img" + i + ".jpg"])
    }
  }, [])

  return (
   <div className="photo-page">
      <section id="title"><h1>Photos</h1></section>
      <Container>
        <section id="section1">
        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2}}>
          <Masonry>
            {items.map((img) => {
              return(<img src={img} alt="gallery-img" className="pt-10 pr-10" style={{"width": "100%"}}/>)
            })}
        </Masonry>
      </ResponsiveMasonry>
        </section>
      </Container>
   </div>
  );
}

export default Photos;

import React, { useState, useEffect } from 'react'
import '../../Covid.scss'
import { Row, Col, Card } from 'react-bootstrap'
import AliceCarousel from 'react-alice-carousel'

function Activity(props) {

    const [activity, setActivity] = useState({"name":""})
    const [items, setItems] = useState([])
    const [bg, setBg] = useState()

    useEffect(()=>{
        if(props.activity){
            setItems([])
            setActivity(props.activity)
            setBg(props.bg)
            let imgPath = props.activity.mediaPath + "/img"
            let videoPath = props.activity.mediaPath + "/video"
            for(var i=1;i<=props.activity.imageCount;i++){
                let img = imgPath + i + ".jpg"
                setItems((prevImages) => [...prevImages, <img src={img} width="300" height="300" />])
            }
            for(var i=1;i<=props.activity.videoCount;i++){
                let video = videoPath + i + ".mp4"
                setItems((prevVideos) => [...prevVideos, <video src={video} width="300" height="300" controls/>])
            }
        }
    }, [props.activity])

    const cardClass = bg + " text-center"
  return (
      <Card className={cardClass} style={{height: "100%"}}>
        <h3>{activity.name}</h3>
        <br/>
        <p>{activity.description}</p>
        <div className="media-carousel">
            <AliceCarousel 
                mouseTracking 
                items={items} 
                autoPlay 
                autoPlayInterval="3000" 
                onSlideChange = {() => {
                    var videoList = document.getElementsByTagName("video");
                    for (var i = 0; i < videoList.length; i++) {
                        videoList[i].pause();
                    }
                }}
                infinite/>
        </div>
      </Card>
    )     
}

export default Activity;

import React from 'react'
import '../../Participate.scss'
import { Container, Card } from 'react-bootstrap';

function CorporatePartnerships() {
  return (
   <div className="partnerships-page">
    <section id="title"><h1>Corporate Partnerships</h1></section>
     <Container>
        <section id="section2">
            <h2>Why Khel Katha?</h2>
            <p>Most of these children do not get an option to pursue sports due to a lack of resources and opportunity. We hope to provide every child with the opportunity to learn sports and have a platform to pursue it professionally. Impacting the lives of these children, one child at a time, is the Khel Katha mission.</p>
          <Card className="yellow">
            <h3>Benefits for the Donors</h3>
            <br/>
            <ul>
                <li>The funds collected will help students to do activities they enjoy doing</li>
                <li>The students could earn money by doing something they enjoy and be good at the same in their professional lives</li>
                <li>Tax exemption is permitted under Income Tax Act Section 80g</li>
                <li>Corporate Social Responsibility (CSR) certificate will be provided to donors</li>
                <li>ACG benefits</li>
            </ul>
            <br/>
            <h3> Donation benefits</h3>
            <br/>
            <ol>
                <li>50,000- A page and logo on our website, logo on our shirts, logo on the certificates and a chance to have a zoom call with the kids after the program</li>
                <li>25,000- A page and logo on our website, logo on the certificates and logo on our shirts</li>
                <li>15,000- Companies logo on the our shirts, logo on the certificates and small write up on website</li>
                <li>10,000- Companies logos on our website and on the certificate distributed to the children</li>
                <li>5,000- Logo on our website</li>
            </ol>
            <p>More the amount you donate, more the difference you make. Any amount however small will be appreciated</p>
            <p>Please contact us for partnerships and donations</p>
          </Card>
        </section>
     </Container>
   </div>
  );
}

export default CorporatePartnerships;

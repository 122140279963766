export const mela = [
    {
        "mediaPath": "/assets/events/mela/mela-2/",
        "images" : 32,
        "videos" : 0,
        "header": ["Date : 21st Jan, 2024", "Venue : Vidyanidhi School Grounds, Juhu"]
    },
    {
        "mediaPath": "/assets/events/mela/mela-1/",
        "images" : 37,
        "videos" : 0,
        "header": ["Date : 22nd Jan, 2023", "Venue : Vidyanidhi School Grounds, Juhu"]
    }
]
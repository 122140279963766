export const vocationals = [
    {
        imgPath : "/assets/vocational/mehendi",
        name: "Mehendi",
        path: "mehendi",
    },
    {
        imgPath : "/assets/vocational/candle",
        name: "Candle Making",
    },
    {
        imgPath : "/assets/vocational/soap",
        name: "Soap Making",
    },
    {
        imgPath : "/assets/vocational/tote",
        name: "Decorate a TOTE Bag",
    },
    {
        imgPath : "/assets/vocational/zentangle",
        name: "Zentangle",
    },
    {
        imgPath : "/assets/vocational/blockprinting",
        name: "Block Printing",
    },
    {
        imgPath : "/assets/vocational/bestofwaste",
        name: "Best out of Waste Activity",
    },
    {
        imgPath : "/assets/vocational/fabric-painting",
        name: "Fabric Painting",
    },
]
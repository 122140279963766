import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function Footer() {
  return (
   <div className="footer pt-50 pb-50">
     <Container>
       <Row  className="text-center">
         <Col md={6}>
            <img src="/kk_logo.png" className="mb-20" height="100"/>
            <h5>Khel Katha</h5>
            <p>Khel Katha is a student run not for profit initiative and the official Sports & Vocational Training wing of Each One Teach One that is working towards providing underprivileged children with the opportunities and the resources to play sports and acquire life skills for their future growth.</p>
         </Col>
         <Col md={6} style={{margin: "auto"}}>
            <h5>Quick Contact</h5>
            <a href="mailto:khelkatha2019@gmail.com">khelkatha2019@gmail.com</a>
            <br/>
            <a href="tel:9821189885">+91-9821189885</a>
            <br/>
            <a href="tel:9821350443">+91-9821350443</a>
            <br/>
            <a href="tel:9821139735">+91-9821139735</a>
         </Col>       
      </Row>
     </Container>
   </div>
  );
}

export default Footer;

import React from 'react'
import '../Participate.scss'
import { Container } from 'react-bootstrap';

function Volunteer() {
  return (
    <div className="volunteer-page">
        <section id="title"><h1>Become a Volunteer</h1></section>
        <Container>
            <section className="text-center">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeHynLFsO06q7tDEFJWoHf1KWumD1W7bahOv5iywviYpFV3Pw/viewform?embedded=true" frameBorder="0" marginHeight="0" marginWidth="0" style={{width:"100%", height:"1400px"}}>Loading…</iframe>   
            </section>
        </Container>
    </div>
  );
}

export default Volunteer;

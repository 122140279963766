import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import App from './components/common/App';
import Header from './components/common/Header';
import Home from './components/pages/Home';
import Objective from './components/pages/Goal/Objective';
import Story from './components/pages/Goal/Story';
import Eoto from './components/pages/Goal/EOTO';
import Drives from './components/pages/Action/Drives';
import Drive from './components/pages/Action/Drives/Drive';
import Activity from './components/pages/Action/Activities/Activity';
import Activities from './components/pages/Action/Activities';
import VocationalTraining from './components/pages/Action/VocationalTraining';
import Vocationals from './components/pages/Action/VocationalTraining/Vocational';
import Team from './components/pages/Team/Team';
import OnlineVocational from './components/pages/Covid/OnlineVocational';
import Vocational from './components/pages/Covid/OnlineVocational/Vocational';
import OnlineActivities from './components/pages/Covid/OnlineActivities';
import OnlineTeaching from './components/pages/Covid/OnlineTeaching';
import Photos from './components/pages/Gallery/Photos';
import Videos from './components/pages/Gallery/Videos';
import Donors from './components/pages/Participate/Donors';
import Donor from './components/pages/Participate/Donors/Donor';
import Donate from './components/pages/Participate/Donate';
import SportsEquipment from './components/pages/Participate/Donate/SportsEquipment';
import CorporatePartnerships from './components/pages/Participate/Donate/Partnerships';
import Volunteer from './components/pages/Participate/Volunteer';
import Contact from './components/pages/Contact';
import Footer from './components/common/Footer';
import OnlineCounselling from './components/pages/Covid/OnlineCounselling';
import Counselling from './components/pages/Covid/OnlineCounselling/Counselling';
import SportsDay from './components/pages/Events/SportsDay';
import Mela from './components/pages/Events/Mela';
import OnlineSports from './components/pages/Covid/OnlineSports';
import Founders from './components/pages/Team/Founders';

ReactDOM.render(
  <React.StrictMode>
    <div>
        <Header/>
        <Router>
          <App>
              <Route exact path='/' component={Home} />
              <Route exact path="/goal/objective" component={Objective} />
              <Route exact path="/goal/story" component={Story} />
              <Route exact path="/goal/eachoneteachone" component={Eoto} />
              <Route exact path="/team/founders" component={Founders} />
              <Route exact path="/team/team" component={Team} />
              <Route exact path="/action/drives" component={Drives} />
              <Route exact path="/action/drives/:driveName" component={Drive} />
              <Route exact path="/action/sportstraining" component={Activities} />
              <Route exact path="/action/vocationals" component={VocationalTraining} />
              <Route exact path="/covid-times/activities" component={OnlineActivities} />
              <Route exact path="/covid-times/teaching" component={OnlineTeaching} />
              <Route exact path="/covid-times/vocational" component={OnlineVocational} />
              <Route exact path="/covid-times/vocational/:vocationalName" component={Vocational} />
              <Route exact path="/covid-times/counselling" component={OnlineCounselling} />
              <Route exact path="/covid-times/counselling/:counsellingName" component={Counselling} />
              <Route exact path="/covid-times/sports" component={OnlineSports} />
              <Route exact path="/events/sports-day" component={SportsDay}/>
              <Route exact path="/events/mela" component={Mela}/>
              <Route exact path="/gallery/photos" component={Photos}/>
              <Route exact path="/gallery/videos" component={Videos}/>
              <Route exact path="/participate/donate" component={Donate} />
              <Route exact path="/participate/donate/sports-equipment" component={SportsEquipment} />
              <Route exact path="/participate/donate/corporate-partnerships" component={CorporatePartnerships} />
              <Route exact path="/participate/volunteer" component={Volunteer} />
              <Route exact path="/participate/donors" component={Donors} />
              <Route exact path="/participate/donors/:donorName" component={Donor} />
              <Route exact path="/contact" component={Contact} />
          </App>
        </Router>
        <Footer/>
    </div>
  </React.StrictMode>
  ,document.getElementById('root')
);

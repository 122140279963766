import React from 'react'
import '../Team.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { core, team, volunteers } from './data'

function Team() {
  return (
   <div className="team-page text-center">
      <section id="title"><h1>Meet the Team</h1></section>
      <Container>
        <section id="section1">
          <div>
            <h2>Core Team</h2>
          </div>
          <div>
            <Row>
              {core.map((member, i) => {
                return(
                  <Col md={12} lg={6} className="grid-col" key={i}>
                    <Row className="border-div">
                      <Col md={6} className="grid-img">
                        <img src={member.imgSrc} alt="image"/>
                      </Col>
                      <Col md={6} className="pt-20">
                        <h3>{member.name}</h3>
                        <h5>{member.designation}</h5>
                        <p className="pt-20">{member.description}</p>
                      </Col>
                    </Row>  
                  </Col>
                )
              })}
            </Row>
          </div>
        </section>
        <section id="section2">
          <div>
            <h2>Team</h2>
          </div>
          <div>
            <Row>
              {team.map((member, i) => {
                return(
                  <Col md={12} lg={6} className="grid-col" key={i}>
                    <Row className="border-div">
                      <Col md={6} className="grid-img">
                        <img src={member.imgSrc} alt="image"/>
                      </Col>
                      <Col md={6} className="pt-20">
                        <h3>{member.name}</h3>
                        <p className="pt-20">{member.description}</p>
                      </Col>
                    </Row>  
                  </Col>
                )
              })}
            </Row>
          </div>
        </section>
        <section id="section2">
          <div>
            <h2>Volunteers</h2>
          </div>
          <div>
            <Row>
              {volunteers.map((member, i) => {
                return(
                  <Col md={12} lg={6} className="grid-col" key={i}>
                    <Row>
                      <Col md={12} className="pt-20">
                        <h3>{member.name}</h3>
                      </Col>
                    </Row>  
                  </Col>
                )
              })}
            </Row>
          </div>
        </section>
      </Container>
   </div>
  );
}

export default Team;

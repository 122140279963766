import React, { useState, useEffect } from 'react'
import {Carousel, Row, Container, Col, Card, Button} from 'react-bootstrap'
// import Ticker from 'react-ticker'
import ModalVideo from 'react-modal-video'
import funds from '../../../assets/home-page/funds.jpeg'
import volunteers from '../../../assets/home-page/volunteers.jpg'
import equipment from '../../../assets/home-page/equipment.png'
import ButtonCard from './ButtonCard'
import Counter from './Counter'
import './Home.scss'
import { Link } from 'react-router-dom'
import { donors1, donors2, donors3 } from '../Participate/Donors/DonorsData'

function Home() {

  const [isOpen, setOpen] = useState(false)
  const [videoUrl, setUrl] = useState()
  const [videoChannel, setChannel] = useState()
  const [donorFirst] = useState(donors1)
  const [donorSec] = useState(donors2)
  const [donorThird] = useState(donors3)

  return (
   <div className="home-page">
    <section id="section1" className="p-0">
      <Carousel>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src="/assets/home/img4.jpeg"
            alt="Fourth slide"
          />
          <Carousel.Caption>
            <h3>Rewrite your sports story</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src="/assets/home/img1.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Sports for all</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src="/assets/home/img2.png"
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>Dream!! Believe !! Achieve !!</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src="/assets/home/img3.jpg"
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Get the sports habit </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src="/assets/home/img5.jpeg"
            alt="Fifth slide"
          />
        </Carousel.Item>
      </Carousel>
     </section>
    <section id="section2">
       <Container>
        <Row>
          <ButtonCard img={funds} text={{heading:"More Charity Better Life"}} link="/participate/donate"/>
          <ButtonCard img={volunteers} text={{heading:"Friendly Volunteers"}} link="participate/volunteer"/>
          <ButtonCard img={equipment} text={{heading:"Sports Equipment Donation"}} link="/participate/donate/sports-equipment"/>
        </Row>
       </Container>
     </section>
    <section id="section3" className="text-center"> 
       {/* <h4>"It doesn’t matter what your background is or where you came from. If you have dreams and goals, that’s all that matters"</h4>
       <p>Serena Williams, professional tennis player</p> */}
       <h4><em>"When people throw stones at you, you turn them into milestones"</em></h4>
       <h6> - Sachin Tendulkar</h6>
     </section>
    <section id="section4">
      <Container>
        <Row>
          <Col md={4}>
            <Row>
              <Col xs={6}>
                <img src="/assets/icons/happy-children.png" style={{width:"80%"}}/>
                <br/>
                <br/>
                <h6>Children Impacted</h6>
              </Col>
              <Col xs={6} style={{margin: "auto"}}>
                <h1><Counter count={3000}/>+</h1>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col xs={6}>
                <img src="/assets/icons/team.png" style={{width:"80%"}}/>
                <br/>
                <br/>
                <h6>Volunteers Contributed</h6>
              </Col>
              <Col xs={6} style={{margin: "auto"}}>
                <h1><Counter count={100}/>+</h1>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
          <Row>
            <Col xs={6}>
              <img src="/assets/icons/sport.png" style={{width:"80%"}}/>
              <br/>
              <br/>
              <h6>Activities Played</h6>
            </Col>
            <Col xs={6} style={{margin: "auto"}}>
              <h1><Counter count={35}/>+</h1>
            </Col>
          </Row>
          </Col>
        </Row>
      </Container>
    </section>
    <section id="section5" className="text-center">
      <Row>
        <Col lg={6}>
          <Card className="testimonial-card">
            <Card.Img src="/assets/home/img1.jpg" alt="Image" />
            <Card.ImgOverlay>
                <Card.Title><h3>Inspiring Dreams, Shaping Futures</h3></Card.Title>
                <br/>
                <Card.Text><strong>
                Khel Katha drives its "Dream Beyond" vision, inspiring innovation and creativity in sports and vocational training to expand horizons, empower communities, and promote self-sustenance. Here’s a brief glimpse of recent highlights.
                  </strong></Card.Text>
                <Button onClick={()=> {setOpen(true); setChannel("custom"); setUrl("/assets/home/video3.mp4")}}>Watch Out >></Button>{' '}
            </Card.ImgOverlay>
          </Card>
        </Col>
        <Col lg={6}>
          <Card className="testimonial-card">
          <Card.Img src="/assets/home/img4.jpg" alt="Image" />
            <Card.ImgOverlay>
                <Card.Title><h3>Our Wow Moments</h3></Card.Title>
                <br/>
                <Card.Text><strong>
                Pandemic or no pandemic Khel Katha has been working tirelessly for the past few years to make the lives of underprivileged children better through sports and vocational training. Here is a sneak peak into what all we have achieved till date.
                  </strong></Card.Text>
                <Button onClick={()=> {setOpen(true); setChannel("youtube"); setUrl("E8tj7EfFgDw")}}>Watch Out >></Button>{' '}
            </Card.ImgOverlay>
          </Card>
        </Col>
      </Row>
    </section>
    <section id="section6">
    <div class="ticker">
      {
        <div class="ticker-move">
          {donorFirst.map(donor => {
            return(
              <Link to={"/participate/donors/" + donor.path}>
                <img src={donor.logoPath} width="100" height="100" className="mr-50"/>
              </Link>
            )
          })}
          {donorSec.map(donor => {
            return(
              <Link to={"/participate/donors"}>
                <img src={donor.logoPath} width="100" height="100" className="mr-50"/>
              </Link>
            )
          })}
          {donorThird.map(donor => {
            return(
              <Link to={"/participate/donors"}>
                <img src={donor.logoPath} width="100" height="100" className="mr-50"/>
              </Link>
            )
          })}
                    {donorFirst.map(donor => {
            return(
              <Link to={"/participate/donors/" + donor.path}>
                <img src={donor.logoPath} width="100" height="100" className="mr-50"/>
              </Link>
            )
          })}
          {donorSec.map(donor => {
            return(
              <Link to={"/participate/donors"}>
                <img src={donor.logoPath} width="100" height="100" className="mr-50"/>
              </Link>
            )
          })}
          {donorThird.map(donor => {
            return(
              <Link to={"/participate/donors"}>
                <img src={donor.logoPath} width="100" height="100" className="mr-50"/>
              </Link>
            )
          })}
        </div>

    }
    </div>
    <ModalVideo channel={videoChannel} videoId = {videoUrl} url={videoUrl} onClose={() => setOpen(false)} isOpen={isOpen}/>
    </section>
   </div>
  );
}

export default Home;

export const drives = [
    {
        imgPath : "/assets/drives/fund_collection/mask_drive",
        name: "Mask Drive",
        path: "mask-drive",
        type: "FUND RAISING DRIVE",
        date: "June 2020",
        heading: "Trendy facemasks in fight against Coronavirus",
        description:<p>The pandemic Corona Virus hit the world and we had to stop the training. As we came to know the children were facing issues with the basic necessities, we decided to raise funds. We organised a fund collecting drive to sell cotton, reusable masks. We all friends met on zoom and discussed how to reach out to people. We have successfully sold more than 1500 masks and plan to continue this drive.</p>
    },
    {
        imgPath : "/assets/drives/distribution_drive/activitykit_drive",
        name: "Activity Kit and Care Package Drive",
        path: "activity-kit-drive",
        type: "DISTRIBUTION DRIVE",
        date: "Sept 2020",
        heading: "An interactive session",
        description:<p>Due to schools being shut we decided to give the students an Educational kit to help them utilise their free time more effectively. We used the fund collected from selling the mask in buying items like Rubik's cube, stationary kit, Origami papers, Sanitiser, Mask, Sudoku sheets, Colouring sheets etc. EOTO helped by giving ration to the families of the school students. On 26th of September we distributed 100 kits to the students at their school. Seeing the smiles on their faces was very rewarding.</p>
    },
    {
        imgPath : "/assets/drives/fund_collection/diya_drive",
        name: "Diya Drive",
        path: "diya-drive",
        type: "FUND RAISING DRIVE",
        date: "Oct 2020",
        heading: "Diyas to light up the lives ",
        description:<p>Diwali was soon approaching. The situation was still very grim. People didn’t have jobs and labours, their daily wages. Therefore Khel Katha decided to distribute care packages to the children. For this we needed funds. We procured 1000 earthen diyas, painted them with festive colours and sold them. Thus were able to raise enough funds for distributing care packages.</p>
    },
    {
        imgPath : "/assets/drives/distribution_drive/diwali_drive",
        name: "Diwali Drive",
        path: "diwali-drive",
        type: "DISTRIBUTION DRIVE",
        date: "Nov 2020",
        heading: "Helping everybody celebrate Diwali",
        description:<p>With no respite in Corona cases, we realised that the families of the Municipal school students were in very bad state. To help them we decided to distribute food & basic hygiene kit which summed up to ₹500/- for each kit. We asked family & friends to help us & we got contribution for around 100 care package. Mapro Mahabaleshwar contributed by donating 300 bottles of crush & 200 packets of Jelly sweets. We even gave them Diyas so that they could decorate their house during Diwali. Seeing happy faces gave us a lot of satisfaction.</p>
    },
    {
        imgPath : "/assets/drives/distribution_drive/newyear_drive",
        name: "New Year Drive",
        path: "new-year-drive",
        type: "DISTRIBUTION DRIVE",
        date: "Jan 2021",
        heading: "The gift of love, of joy, of peace",
        description:<p>In January we decided to have another distribution drive for the Municipal School students. We wanted to give them prizes for different online activities like Diya painting, public speaking & Rubix cube solving. To help them for their exams, we gave each of  them a Writing board & Geometry Box. Any outdoor activity was still restricted so we gave them a skipping rope to keep them physically fit. As the distribution was aligning with Sankrant, we also gave Til ke Laddoo & some snacks. The students were elated to get so many different things.</p>
    },
    {
        imgPath : "/assets/drives/fund_collection/soaps_drive",
        name: "Soaps Drive",
        path: "soaps-drive",
        type: "FUND RAISING DRIVE",
        date: "October 2021",
        heading: "Soap for a Cause",
        description:<p>We conducted the vocational training program for the children with the help of Each one Teach one in the year 2020. In one of the programs they were taught to make soaps. The children made beautiful soaps in different shapes and sizes. We felt they should be encouraged. Therefore we took part in a Diwali Mela where we sold all 80 soaps. In the mela we even spoke about our NGO Khel Katha. We explained people the different activities we are doing with children and requested people to be a part of our cause and help us raise funds.</p>
    },
    {
        imgPath : "/assets/drives/distribution_drive/care_package_drive",
        name: "Care Package Drive",
        path: "care-package-drive",
        type: "DISTRIBUTION DRIVE",
        date: "Aug 2021",
        heading: "Helping Hand",
        description:<p>The situation hadn’t become normal yet and people still needed help and support as the pandemic had left lots of people jobless. Therefore we gave care packages to around 80 children of Tata Municipal School. These packages included rice, dal, tea, poha, biscuits, Maggie etc. We even distributed gifts to the children who participated in a tongue twister online game. We gave them gifts such as water bottles and tiffin boxes which they could use once the schools reopened.</p>
    },
    {
        imgPath : "/assets/drives/distribution_drive/diwali-drive-22",
        name: "Diwali",
        path: "diwali-drive-22",
        type: "DISTRIBUTION DRIVE",
        date: "Oct 2022",
        heading: "Diwali Happiness",
        description:<p>Finally we were back to school doing what we like to do. After a gap of 2 years we met the kids of Tata school to celebrate Diwali with them. It was fun to meet them personally and not online. On interacting with them we learnt how much they are enjoying vocational training. Many children came forward and spoke about how much they learned and that they will be pursuing it in future. It felt gratifying that our efforts are being paid off. Then we distributed sweets and snacks as it was Diwali time. Rotary club of India was very kind to give chocolates to these kids. The children were all big smiles after receiving their goody bags.</p>
    },
    {
        imgPath : "/assets/drives/distribution_drive/donation-drive",
        name: "Donation Drive",
        path: "donation-drive",
        type: "DISTRIBUTION DRIVE",
        date: "July 2024",
        heading: "",
        description:<p>We did a donation drive on 10th July 2024 at Palghar school. There are around 726 students in the school. We distributed <ol> <li> Sanitary napkins (1008 packets)& dental kit (360 packets)sponsored by Awantika Chowdhury. </li><li> Parle G cakes & biscuits (350 packets)  were sponsored by Soham & Dhruv Chauhan. </li><li> Dairy milk (125) & Oreo biscuits (75) were sponsored by Rotary. </li><li> Notebooks (720) & Pav Vada (730 pcs) was sponsored by Khel Katha </li></ol></p>
    },
    {
        imgPath : "/assets/drives/distribution_drive/shoe-drive",
        name: "Shoe Drive",
        path: "shoe-drive",
        type: "DISTRIBUTION DRIVE",
        date: "Sep 2024",
        heading: "",
        description:<p>It’s that time of year again for our annual giveaway drive, just in time for Diwali. Children from Khel Katha visited the government school where they regularly teach sports. During this visit, we distributed 60 pairs of shoes to children aged 6 to 16. To ensure everyone received the right size, we had collected their shoe measurements in advance. The children were thrilled to receive new shoes, especially since many of them didn’t even own a single pair. These shoes will help them participate more comfortably in sports activities. In celebration of one of our volunteers’ birthdays, we also distributed stationery to the children. Since it was Diwali time we gave them hampers containing essentials like soaps and a tote bag.</p>
    },
]




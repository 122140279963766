import React from 'react'
import '../Covid.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { activities } from './CounsellingData'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

function OnlineCounselling() {
  return (
   <div className="activity-page">
     <section id="title"><h1>Online Vocational Activities</h1></section>
      <section id="actions-section">
        <Container>
          {/* <p>The start of 2021 looked no better than the end of 2020, and people were still badly affected by the pandemic. Each of them belonged to low-income households, and with covid their financial situation worsened.  </p>
          <p>We realised that we needed to make them financially stable so that they could continue their studies & support their family as well.  </p>
          <p>We then came up with the idea of Vocational Training. This would teach them critical skills that could help them not only now, but also later on in life, and some might enjoy it even more than their studies. It would also enable them to have higher wages and chances of employment.  </p>
          <p>These vocational skills would include yoga, fabric painting, dancing, hair & makeup, Zentangle, soap making & Mehendi. We shortlisted those skills which is relatively easy & less time consuming to grasp. These require minimum investment as well. Therefore we drafted a proposal to raise funds. We pitched the idea to friends and family who really encouraged us by generously sponsoring us.  </p>
          <p>So we started identifying teachers who could do these online classes for these students. We decided to take one activity each month. We would teach them once a week and these would give them basic idea about each activity & if they enjoy it then later we could enroll them to a offline Vocational training centre so that they could become proficient in these skills.  We began the Online Vocational Training activity from July 2021.</p> */}
        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2}}>
          <Masonry>
            {activities.map((activity, i) => {
              let blogClass = i%2 == 0 ? "blog-col yellow-bg" : "blog-col blue-bg"
              let link = "./counselling/" + activity.path
              return(
                <Link to={link}>
                  <div className={blogClass}>
                    <img src={activity.mediaPath + "/img1.jpg"} alt="activity-img"/>
                    <Row>
                        <Col xs={8} className="blog-heading">
                          <h2>{activity.name}</h2>
                        </Col>
                    </Row>
                  </div>
                </Link>
              )
            })}
        </Masonry>
      </ResponsiveMasonry>
      </Container>
      </section>
   </div>
  );
}

export default OnlineCounselling;

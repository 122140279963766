export const online_activites = [
    {
        mediaPath : "/assets/online_activities/summer_camp",
        name : "Summer Camp",
        imageCount: 8,
        videoCount: 0,
        description : "Each One Teach One organised an online summer camp for students of the Municipal school in May & June 2021 as lockdown continued in India. Khel Katha contributed by getting teachers for the same. We made a pamphlet & circulated amongst our group for volunteers. We managed to get around 15 volunteer teachers for activities such as Yoga, Art & Craft, drawing, fitness, Dance, English speaking etc."
    },
    {
        mediaPath : "/assets/online_activities/best_of_waste",
        name : "Best of waste",
        imageCount: 8,
        videoCount: 2,
        description : "We had limited resources to give the children. We wanted to teach them to be mindful of the environment and the available resources. By explaining how to make things from waste like plastic bottle we taught the concept of reuse and recycle."
    },
    {
        mediaPath : "/assets/online_activities/mask_making",
        name : "Mask Making",
        imageCount: 2,
        videoCount: 4,
        description : "Mask had become the most essential part of one’s dressing before leaving the house. We had given children mask. One mask was not enough. But how many could be given. So we taught them how to make mass with rags available at home. They made colourful masks."
    },
    {
        mediaPath : "/assets/online_activities/sudoku",
        name : "Sudoku Competition",
        imageCount: 9,
        videoCount: 0,
        description : "Maths is an integral part of the development of a child’s brain. They say solving a sudoku every day makes the brain active and alert. Therefore we gave 10 sheets of sudoku to each child and made tutorials to teach. We started with an easy 4 x 4 grid and went on to teach 9 * 9."
    },
    {
        mediaPath : "/assets/online_activities/rubiks",
        name : "Rubik's Cube Competition",
        imageCount: 1,
        videoCount: 4,
        description : "Our aim during lockdown was to keep the children busy and still teach something as the schools had closed. We gave Rubiks cube so that it would keep them entertained for hours. That’s what happened. They send us  videos of themselves actually solving. They could follow instructions and learn."
    },
    {
        mediaPath : "/assets/online_activities/diya_painting",
        name : "Diya Painting",
        imageCount: 7,
        videoCount: 2,
        description : "We wanted every house to be lit by diyas in the gloomy days of pandemic.  We gave every family diyas and asked them to paint it the way they want. They really surprised us by not only painting,  but even decorating it with stones and glitter."
    },
    {
        mediaPath : "/assets/online_activities/public_speaking",
        name : "Public Speaking",
        imageCount: 0,
        videoCount: 4,
        description : "Speaking in public can make the best of people have butterflies in their stomach. We thought Public Speaking is an important skill in todays world to express ourselves confidently in front of people.  We taught them voice modulation and body language. We were impressed with the videos of the public speaking. They were all quick learners."
    },
    {
        mediaPath : "/assets/online_activities/origami",
        name : "Origami Competition",
        imageCount: 17,
        videoCount: 0,
        description : "We had even given them origami papers in the care packages.  Then we send some tutorials to teach what all shapes they can make with the coloured pieces of paper. They did all that we taught and came up with their own innovations also. They made all kinds of animals, wall hangings and decorative pieces."
    },
    {
        mediaPath : "/assets/online_activities/coloring_competition",
        name : "Coloring Competition",
        imageCount: 4,
        videoCount: 0,
        description : "In our care packages we had given colouring sheets to the children so that they had some activity to do in lock down and keep themselves busy. When they send the pictures of the sheets after colouring,we knew the kids really enjoyed it. They were beautifully painted with the myriad of colours."
    },
    {
        mediaPath : "/assets/online_activities/talent_hunt",
        name : "Talent Hunt",
        imageCount: 6,
        videoCount: 0,
        description : "When we thought of doing talent hunt with them little did we expect from it. But they surpassed all our expectations by showing us a variety of fields they were talented in and they sent us videos of cooking drawing magic tricks mehendi and many more skills in spite of having limited resources available to them."
    },
    {
        mediaPath : "/assets/online_activities/dancing",
        name : "Dancing Activities",
        imageCount: 0,
        videoCount: 1,
        description : "India is a land of Bollywood dancing everyone loves to dance on film music. Therefore we thought the best way to start  the online activities would be to have a dance competition. As we assumed it was a big hit.  Lots of children send their videos of dancing on different songs."
    }
]
import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import AliceCarousel from 'react-alice-carousel'
import ReactPlayer from 'react-player/lazy'
import { mela } from './MelaData'


function Mela(props) {
    const [items,setItems] = useState([])

    useEffect(() => {
      const temp = []
      for(let sport of mela){
        const media = []
        const mediaPath = sport.mediaPath
      
        for(var i=1;i<=sport.images;i++){
            media.push(<img src={mediaPath + "img" + i + ".jpg"} width="600" height="500" />)
        }
  
        for(var i=1;i<=sport.videos;i++){
            media.push(<ReactPlayer 
              className='react-player'
              url={mediaPath + "video" + i + ".mp4"}
              controls 
              width='100%'
              height='100%'
          />)
        }

        temp.push(
          {
            ...sport,
            media: media
          }
        )
      }

      setItems(temp)

    }, [])


  return (
    <div className="events-mela-page">
      <section id="title"><h1>Mela</h1></section>
      <p className='pt-50 pl-50 pr-50'>Every February, Khel Katha hosts its largest event of the year: "THE MELA." This vibrant celebration attracts over 450 attendees, including parents and children of all ages.</p>
      <p className='pl-50 pr-50'>Khel Katha provides online vocational training to government school students who lack the resources to learn these skills. We realized that merely teaching these skills wasn't enough to convey their true value. To address this, we organized the Mela to showcase and sell products made by the Each One Teach One (EOTO) students. We offer training in various crafts such as Mehendi, candle making, soap making, bag decoration, block printing, upcycling waste materials into coasters, and Zentangle Art, with dedicated stalls for each.</p>
      <p className='pl-50 pr-50'>The Mela also features a range of game stalls, including Feed the Clown, Smash the Cans, Chopstick Game, and Toss the Ring. There are selfie corners, art corners, and a stage for performances where budding dancers, singers, and musicians can shine. The talent displayed by our participants is truly impressive, often surpassing our expectations. For the youngest attendees, we set up a ball pool.</p>
      <p className='pl-50 pr-50'>We also create an art wall showcasing paintings by EOTO children and award gifts and certificates to the top five artists to encourage their creativity. The food stalls offer a variety of delicious options, including pizza, popsicles, and chole bhature.</p>
      <p className='pl-50 pr-50'>To ensure the event's success, we enlist volunteers from our network of friends, who have responded overwhelmingly to support us. The Mela's success and the appreciation for the children’s work affirm that we are moving in the right direction and motivate us to continue our efforts with renewed vigor.</p>
      {items.length > 0 && items.map((item) => {
        return ( 
        <section id="activity-section">
           <Container className="data-desc text-center">
              {item.header.map((header) => <h3>{header}</h3>)}
          </Container>
          <AliceCarousel 
            autoHeight   
            items={item.media} 
            onSlideChange = {() => {
                var videoList = document.getElementsByTagName("video");
                for (var i = 0; i < videoList.length; i++) {
                    videoList[i].pause();
                }
            }}
            />
      </section>)
      })}
    </div>
  );
}

export default Mela
import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import AliceCarousel from 'react-alice-carousel'
import ReactPlayer from 'react-player/lazy'
import { sports } from './SportsData'

function SportsDay() {
    const [items, setItems] = useState([])

    useEffect(() => {
      const temp = []
      for(let sport of sports){
        const media = []
        const mediaPath = sport.mediaPath
      
        for(var i=1;i<=sport.images;i++){
            media.push(<img src={mediaPath + "img" + i + ".jpg"} width="600" height="500" />)
        }
  
        for(var i=1;i<=sport.videos;i++){
            media.push(<ReactPlayer 
              className='react-player'
              url={mediaPath + "video" + i + ".mp4"}
              controls 
              width='100%'
              height='100%'
          />)
        }

        temp.push(
          {
            ...sport,
            media: media
          }
        )
      }

      setItems(temp)

    }, [])   

  return (
    <div className="events-sports-page">
      <section id="title"><h1>Sports Day</h1></section>
      <p className='pt-50 pl-50 pr-50'>
      Each year, Khel Katha and Each One Teach One host a sports day for the 22 municipal schools in Maharashtra. Around 150 to 200 children participate. We begin the event with a brief prayer and then lead the children in a Zumba warm-up. Approximately one hundred children participate in various athletic competitions, including the 100-meter and 200-meter races, relays, and long jumps. We organise special races for our  youngest participants, ages 3 to 5 years. Deifferent races are held for their parents also. Winning parents are given special prizes. </p>
      <p className='pl-50 pr-50'>We invite volunteers from different schools to help ensure the event runs smoothly. Winners are awarded certificates, medals, and chocolates. To add to the festive atmosphere, especially since the sports day is held in December, we also have Santa Claus entertain the children.</p>
      {items.length > 0 && items.map((item) => {
        return ( 
        <section id="activity-section">
           <Container className="data-desc text-center">
              {item.header.map((header) => <h3>{header}</h3>)}
          </Container>
          <AliceCarousel 
            autoHeight   
            items={item.media} 
            onSlideChange = {() => {
                var videoList = document.getElementsByTagName("video");
                for (var i = 0; i < videoList.length; i++) {
                    videoList[i].pause();
                }
            }}
            />
      </section>)
      })}
     
    </div>
  );
}

export default SportsDay
import React from 'react'
import '../Team.scss'
import { Container, Row, Col } from 'react-bootstrap'
import { founders } from './data'

function Founders() {
  return (
   <div className="team-page text-center">
      <section id="title"><h1>Meet the Founders</h1></section>
      <Container>
        <section id="section1">
          <div>
            <Row>
              {founders.map((member, i) => {
                return(
                  <Col md={12} className="grid-col" key={i}>
                    <Row className="border-div">
                      <Col lg={3} md={6} className="grid-img">
                        <img src={member.imgSrc} alt="image"/>
                      </Col>
                      <Col lg={9} md={6} className="pt-20">
                        <h3>{member.name}</h3>
                        <p className="pt-20">{member.description}</p>
                      </Col>
                    </Row>  
                  </Col>
                )
              })}
            </Row>
          </div>
        </section>
      </Container>
   </div>
  );
}

export default Founders;

import React, { useState, useEffect } from 'react'
import '../../Action.scss'
import { Container, Row, Col } from 'react-bootstrap'
import ImageGallery from 'react-image-gallery';
import {drives} from '../DriveData'

function Drive(props) {
  const [driveData, setDriveData] = useState({})
  const [images, setImages] = useState([])
 
  useEffect(() => {
    setDriveData(drives.find(x => x.path === props.match.params.driveName))
  },[])

  useEffect(() => {
    if(driveData.imgPath){
      let imagePath = driveData.imgPath + "/img"
      var imgs = 0
      switch(driveData.path){
        case "diya-drive":
          imgs = 3
          break;
        case "mask-drive":
          imgs = 9
          break;
        case "new-year-drive":
          imgs = 6
          break;
        case "diwali-drive":
          imgs = 6
          break;
        case "activity-kit-drive":
          imgs = 5
          break;
        case "care-package-drive":
          imgs = 6
          break;
        case "soaps-drive":
          imgs = 3
          break;
        case "diwali-drive-22":
          imgs = 14
          break;
        case "donation-drive":
          imgs = 15
          break;
      }

      for(var i=1;i<=imgs;i++){
        images.push({
          original: imagePath + i + ".jpg",
          thumbnail: imagePath + i + ".jpg"
        })
      }
    }
  }, [driveData])

  return (
    <div className="drive-page">
      <section id="title"><h1>{driveData.name}</h1></section>
      <section id="drive-section">
        <ImageGallery items={images} />
        <Container className="pt-50 data-desc">
          <Row>
            <Col md={3} style={{margin: "auto"}}>
              <img src={driveData.imgPath + "/img0.jpg"} style={{"width": "100%"}}/>
            </Col>
            <Col md={9} style={{margin: "auto", paddingTop: "30px"}}>
              <h4>{driveData.heading}</h4>
              {driveData.description}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Drive